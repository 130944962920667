<template>
  <v-container>
    <h3 class="mt-4">Live Users</h3>
    <v-row>
      <v-col cols="auto">
        <v-btn small color="primary" class="white--text font-weight-medium" @click="getLiveUsers();">Get Live
          Users</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <!-- Active and Inactive Users -->
        <!-- Tooltip of active users -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" color="success" class="white--text">
              {{ userCount('Active') }} Active Users
            </v-chip>
          </template>
          <span v-html="userList('Active')"></span>
        </v-tooltip>
      </v-col>
      <v-col cols="auto">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" color="warning" class="white--text">
              {{ userCount('Inactive') }} Inctive Users
            </v-chip>
          </template>
          <span v-html="userList('Inactive')"></span>
        </v-tooltip>
      </v-col>
    </v-row>
    <!-- Topic One -->
    <h3 class="mt-4">Permissions: Apply</h3>
    <v-row>
      <!-- Override all perms to user role -->
      <v-col cols="auto">
        <v-btn small color="primary" class="black--text font-weight-medium" @click="overrideAllPerms();">Override All
          Perms: {{ permissions_status }}</v-btn>
      </v-col>
    </v-row>
    <br />
    <hr />
    <h3 class="mt-4">Clear Persistence Cache</h3>
    <v-row>
      <!-- Clear persistence cache -->
      <v-col cols="auto">
        <v-btn small color="primary" class="black--text font-weight-medium" @click="clearPersistenceCache();">Clear
          Persistence Cache: {{ clearcache_status }}</v-btn>
      </v-col>
    </v-row>
    <br />
    <hr />
    <h3 class="mt-4">Resource Links</h3>
    <v-row>
      <!-- Add a resource Link to a Campaign->Burst->Screen -->
      <v-col cols="auto">
        <v-select :items="campaigns" key="id" value="id" :item-text="item => `${item.name}`" v-model="campaign_id"
          label="Campaign" :outlined="true" hide-details @change="campaignChange" return-object></v-select>
      </v-col>
      <v-col cols="auto">
        <v-select :items="bursts" key="id" value="id" :item-text="item => `${item.name}`" v-model="burst_id"
          label="Burst" :outlined="true" hide-details @change="burstChange" return-object></v-select>
      </v-col>
      <v-col cols="auto">
        <v-select :items="screens" key="id" value="id"
          :item-text="item => `${item.screen.mediaOwner.friendlyName} - ${item.screen.name}`" v-model="screen_id"
          label="Screen" :outlined="true" hide-details @change="screenChange" return-object></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-select :items="levels" value="value" item-text="name" v-model="level" label="Level" :outlined="true"
          @change="filterResourceTypes(level)" hide-details></v-select>
      </v-col>
      <v-col cols="auto">
        <v-select :items="resourceTypes" key="id" value="shortName" item-text="shortName"
          v-model="selectedResourceLinkShortName" label="Resource Type" :outlined="true" hide-details></v-select>
      </v-col>
      <v-col>
        <v-btn min-width="250px" style="height: 56px; font-size: 15px;" color="primary"
          @click="addResourceLink(level);">
          Save
        </v-btn>
      </v-col>
    </v-row>
    <br/><hr/>
    <h3 class="my-4">Upload Master Container List</h3>
    <v-row>
    <div>
      <div 
            @dragover.prevent 
            @drop.prevent 
            @click="$refs.referenceSheetUpload.click()"
          >
            <input 
              ref="referenceSheetUpload" 
              type="file" 
              hidden 
              multiple
              @click="$refs.referenceSheetUpload.value = ''"
              @change="dragReferenceSheet" 
            />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <div 
                    :ref="'dragDiv1'" 
                    v-bind="attrs"
                    v-on="on"
                    @drop="dragReferenceSheet" 
                    @dragleave="$refs.dragDiv1.style.backgroundColor = 'transparent'"
                    @dragover="$refs.dragDiv1.style.backgroundColor = 'grey'"
                    style="
                      background-color: transparent;
                      padding: 0.66em 0em;
                      transition: 0.1s;
                    "
                  :style="hover ? {border: 'solid 1px rgba(255,255,255, .8)'} : {border: 'solid 1px rgba(255,255,255, .3)'}"
                  class="rounded ma-4">

                  <div class="mt-4" style="width: 100%; text-align: center;">
                    <v-icon large v-on="on">
                      mdi-cloud-upload
                    </v-icon>
                  </div>

                  <div style="width:100%; text-align:center;">
                    Upload Reference Sheet
                  </div>
                  <br>
                  <div v-if="referenceSheet.length > 0">
                    <ul :key="referenceSheet[0].name">
                      <li>{{ referenceSheet[0].name }}</li>
                    </ul>
                  </div>
                </div>
              </v-hover>
            </template>
            <span>Upload Reference Sheet</span>
          </v-tooltip>
        </div>
        <div>
          <v-row>
            <v-col class="pa-0 px-3 ma-0">
              <v-btn v-if="referenceSheet.length > 0" color="primary" block small
                @click="uploadReferenceSheet">Upload</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-row>
    <br />
    <v-row v-if="uploadMasterReferenceListResponse.length > 0" cols="12">
      <v-col cols="auto">
        <h3 class="ma-4">Response</h3>
        <div class="ma-4 pa-1" style="background-color: #999; width: 100%">
          <div v-for="item in uploadMasterReferenceListResponse" :key="item">
            {{item}}
          </div>
        </div>
      </v-col>
    </v-row>
    <br />
    <hr />

    <h3 class="my-4">Upload Master Locations List</h3>
    <v-row>
      <v-col cols="4">
        <v-combobox v-model="masterSheetMediaOwner" :items="mediaOwners" item-text="friendlyName"
          item-value="friendlyName" label="Media Owner Friendly Name" return-object outlined />
      </v-col>
      <v-col cols="4">
        <div>
          <div @dragover.prevent @drop.prevent @click="$refs.masterListSheetUpload.click()">
            <input ref="masterListSheetUpload" type="file" hidden multiple
              @click="$refs.masterListSheetUpload.value = ''" @change="dragMasterListSheet" />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <div :ref="'dragDiv2'" v-bind="attrs" v-on="on" @drop="dragMasterListSheet"
                    @dragleave="$refs.dragDiv2.style.backgroundColor = 'transparent'"
                    @dragover="$refs.dragDiv2.style.backgroundColor = 'grey'" style="
                        background-color: transparent;
                        padding: 0.66em 0em;
                        transition: 0.1s;
                      "
                    :style="hover ? {border: 'solid 1px rgba(255,255,255, .8)'} : {border: 'solid 1px rgba(255,255,255, .3)'}"
                    class="rounded ma-4">

                    <div class="mt-4" style="width: 100%; text-align: center;">
                      <v-icon large v-on="on">
                        mdi-cloud-upload
                      </v-icon>
                    </div>

                    <div style="width:100%; text-align:center;">
                      Upload Master List Sheet
                    </div>
                    <br>
                    <div v-if="masterListSheet.length > 0">
                      <ul :key="masterListSheet[0].name">
                        <li>{{ masterListSheet[0].name }}</li>
                      </ul>
                    </div>
                  </div>
                </v-hover>
              </template>
              <span>Upload Master List Sheet</span>
            </v-tooltip>
          </div>
          <div>
            <v-row>
              <v-col class="pa-0 px-3 ma-0">
                <v-btn v-if="masterListSheet.length > 0" color="primary" block small
                  @click="uploadMasterLocationsList">Upload</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <br />
    <v-row v-if="uploadMasterLocationsListResponse.length > 0" cols="12">
      <v-col cols="12">
        <h3 class="ma-4">Response</h3>
        <div class="ma-4 pa-1" style="background-color: #999; width: 100%">
          <div v-for="item in uploadMasterLocationsListResponse" :key="item">
            {{item}}
          </div>
        </div>
      </v-col>
    </v-row>
    <br />
    <hr />
    <h3 class="my-4">Download Playout Raw Report</h3>
    <v-row>
      <v-col key="1" cols="12" sm="6" style="display: flex;">
        <v-menu ref="datePickerMenu" v-model="datePickerMenu" :close-on-content-click="false" :nudge-right="40"
          :return-value.sync="datePickerRange" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="formattedDateRange" label="Selected Date Range" hide-details="auto" outlined dense
              readonly dark style="max-width: 300px; min-width: 250px; width: 100%;" v-bind="attrs" v-on="on">
              <template v-slot:append>
                <v-icon @click="datePickerMenu = !datePickerMenu">mdi-calendar</v-icon>
              </template>
            </v-text-field>
          </template>
          <v-card>
            <v-container class="pa-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-date-picker ref="leftDatePicker" v-model="datePickerRange" type="date" range lang="en"
                    value-type="format" class="datepicker-dark" :no-title="true" :show-current="false">
                  </v-date-picker>
                </v-col>
                <v-col cols="12" md="6">
                  <v-date-picker ref="rightDatePicker" v-model="datePickerRange" type="date" range lang="en"
                    value-type="format" class="datepicker-dark" :no-title="true" :show-current="false">
                  </v-date-picker>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-menu>
        <v-select v-model="granularity" hide-details="auto" :items="['Daily', 'Hourly']" label="Granularity" outlined
          dark dense style="max-width: 115px;margin-left: 10px;"></v-select>
        <v-text-field v-model="customFileName" label="Custom File Name" outlined dark dense></v-text-field>
      </v-col>
      <v-col cols="12" md="auto">
      </v-col>
    </v-row>
    <v-col cols="12" md="12">
      <v-header>Tips: Add value if needed (Need to click "Add"))</v-header>
    </v-col>
    <v-row>
      <v-col cols="12" md="2">
        <v-select v-model="selectedKey" :items="keys" label="Select Key" outlined dense></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field v-model="keyValue" label="Enter Value" outlined dense></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="primary" @click="addParam">Add</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list dense>
          <v-list-item v-for="(item, index) in paramsList" :key="index">
            <v-list-item-content>{{ item.key }}: {{ item.value }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col key="2" cols="12" sm="6" class="d-flex justify-space-between">
        <v-btn min-width="250px" style="height: 56px; font-size: 15px;" color=secondary @click="resetAll">
          Clear All
        </v-btn>
        <v-btn min-width="250px" style="height: 56px; font-size: 15px;" color="primary" @click="directExportPlayout();">
          Download
        </v-btn>
      </v-col>
    </v-row>
    <br />
    <v-row v-if="uploadMasterLocationsListResponse.length > 0" cols="12">
      <v-col cols="12">
        <h3 class="ma-4">Response</h3>
        <div class="ma-4 pa-1" style="background-color: #999; width: 100%">
          <div v-for="item in uploadMasterLocationsListResponse" :key="item">
            {{ item }}
          </div>
        </div>
      </v-col>
    </v-row>
    <br />
    <hr />
  </v-container>
</template>

<script>
import DevController from '@/services/controllers/_Dev'
import BespokeController from '@/services/controllers/Bespoke'
import CampaignController from '@/services/controllers/Campaign'
import ScreenController from '@/services/controllers/Screen'
import UserController from '@/services/controllers/User'
import ReportingController from '@/services/controllers/Reporting'
import moment from 'moment'

export default {
  data() {
    return {
      permissions_status: '',
      clearcache_status: '',
      selectedResourceLinkShortName: null,
      resourceTypesAll: [],
      resourceTypes: [],
      campaign: null,
      campaigns: [],
      bursts: [],
      screens: [],
      campaign_id: null,
      burst_id: null,
      screen_id: null,
      levels: [{name:"Campaign", value: "campaign"},{name:"Burst", value: "burst"},{name:"Screen", value: "screen"}],
      level: "campaign",
      referenceSheet: [],
      uploadMasterReferenceListResponse: [],
      masterListSheet: [],
      uploadMasterLocationsListResponse: [],
      masterSheetMediaOwner: "",
      mediaOwners: [],
      liveUsers: [],
      //For DirectExportPlayout
      selectedDateTimeRange: [],
      formattedDateRange: [],
      datePickerRange: [],
      datePickerMenu: false,
      granularity: 'Daily',
      customFileName: 'All_Kinectic_Data',
      downloadingReport: false,
      selectedKey: '',
      keyValue: '',
      keys: ['orderId', 'buyerCampaignref', 'spacemediaownerid', 'creativeid', 'creativename' ],
      paramsList: [],
    }
  },
  watch: {
    datePickerRange(newVal) {
      if (newVal.length === 2) {
        const [startDate, endDate] = newVal;
        if (new Date(startDate) > new Date(endDate)) {
          this.datePickerRange = [endDate, startDate];
        }
        this.selectedDateTimeRange = this.datePickerRange;
        this.formattedDateRange = this.formatDateRange(this.datePickerRange);
        this.$nextTick(() => {
          this.datePickerMenu = false;
        });
      }

      if (newVal.length === 1 && newVal[0]) {
        this.formattedDateRange = this.formatDateRange(newVal);
      }
    }
  },
  created () {
    this.getResourceTypes("screen")
    this.getResourceTypes("burst")
    this.getResourceTypes("campaign")
    this.getAllCampaigns()
    this.getMediaOwners()
    this.getLiveUsers();
  },

  computed: {
    
  },

  methods: {
    getLiveUsers() {
      UserController.getLiveUsers()
        .then((res) => {
          this.liveUsers = res.data
        })
    },

    userList(state) {
      return this.liveUsers
      .filter(x => x.browserState == state)
      .map(x => x.userEntity.contactCard.firstName + " " + x.userEntity.contactCard.surname + " - " + x.userEntity.contactCard.email + " - "  + moment(x.lastActive).format('DD MMM YYYY HH:mm a'))
      .join("<br/>")
    },

    userCount(state) {
      return this.liveUsers.filter(x => x.browserState == state).length
    },

    uploadReferenceSheet() {
      this.uploadMasterReferenceListResponse = ["Response log will be emailed to you when complete"]
      const formData = new FormData()
      formData.append('file', this.referenceSheet[0])
      ScreenController.uploadMasterReferenceList(formData)
        .then((res) => {
          this.uploadMasterReferenceListResponse = res.data
          this.$root.$emit('snackbarSuccess', 'Successfully uploaded reference sheet')
        })
        .catch((err) => {
          this.uploadMasterReferenceListResponse = [err.response.data.message]
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        })
    },

    uploadMasterLocationsList() {
      this.uploadMasterLocationsListResponse = ["Response log will be emailed to you when complete"]
      const formData = new FormData()
      formData.append('masterList', this.masterListSheet[0])
      formData.append('mediaOwnerFriendlyName', this.masterSheetMediaOwner.friendlyName)
      ScreenController.uploadMasterLocations(formData)
        .then((res) => {
          this.uploadMasterLocationsListResponse = res.data
          this.$root.$emit('snackbarSuccess', 'Successfully uploaded master list for ' + mediaOwnerFriendlyName)
        })
        .catch((err) => {
          this.uploadMasterLocationsListResponse = [err.response.data.message]
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        })
    },

    dragReferenceSheet(e) {
      if (e.dataTransfer === undefined) {
        this.referenceSheet = Object.values(e.target.files)
      } else {
        this.referenceSheet = Object.values(e.dataTransfer.files)
      }
      this.$refs.dragDiv1.style.backgroundColor = 'transparent'
    },

    dragMasterListSheet(e) {
      if (e.dataTransfer === undefined) {
        this.masterListSheet = Object.values(e.target.files)
      } else {
        this.masterListSheet = Object.values(e.dataTransfer.files)
      }
      this.$refs.dragDiv1.style.backgroundColor = 'transparent'
    },

    getMediaOwners() {
        ScreenController.getMOList()
        .then((res) => {
          this.mediaOwners = res.data
          this.mediaOwners.sort((a,b) => a.friendlyName.localeCompare(b.friendlyName))
        })
      },

    async getAllCampaigns() {
        // Get all campaigns
        const { data = null } = await CampaignController.getAllCampaigns()
        // Catch error
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

        // If the response contains valid data then append
        if(data) {
          this.campaigns = data.sort((a, b) => a.name.localeCompare(b.name))
        }
        else {
          this.campaigns = []
        }
      },

    async getCampaign(campaignId)
    {
        // Get campaign
        const { data = null } = await CampaignController.getCampaign(campaignId)
        // Catch error
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

        // If the response contains valid data then append
        if(data) {
          this.campaign = data
          this.bursts = this.campaign.campaignBursts
          this.bursts = this.bursts.sort((a, b) => a.name.localeCompare(b.name))
        }
        else {
          this.campaign = null
        }
    },

    campaignChange()
    {
      this.selectedResourceLinkShortName = null
      this.bursts = []
      this.screens = []
      this.burst_id = null
      this.screen_id = null
      this.getCampaign(this.campaign_id.id)
    },

    burstChange()
    {
      this.selectedResourceLinkShortName = null
      this.screens = this.burst_id.campaignBurstScreens
      this.screens = this.screens.sort((a, b) => (a.screen.mediaOwner.friendlyName+a.screen.name).localeCompare(b.screen.mediaOwner.friendlyName+b.screen.name))
      this.screen_id = null
    },

    screenChange()
    {
      this.selectedResourceLinkShortName = null
    },

    //
    getResourceTypes(level) {
      CampaignController.getResourceTypes(level)
          .then((res) => {
            this.resourceTypesAll.push({"level": level, "data": res.data})
            if(this.resourceTypesAll.length == this.levels.length)
              this.filterResourceTypes(this.level)
          })
          .catch((err) => {
            
          })
    },

    filterResourceTypes(level) {
      if(this.resourceTypesAll.length > 0)
      {
        this.resourceTypes = this.resourceTypesAll.filter(x => x.level == level)[0].data
      }
    },

    // Override all perms with role of user
    overrideAllPerms() {
      this.permissions_status = 'processing';
      DevController.overrideAllPerms()
      .then((res) => {
        this.permissions_status = 'success'
      })
      .catch(err => {
        this.permissions_status = 'error'
      })
    },

    // Clear Persistence Cache
    clearPersistenceCache() {
      this.clearcache_status = 'processing';
      BespokeController.clearPersistenceCache()
      .then((res) => {
        if(res.data)
          this.clearcache_status = 'success'
        else
          this.clearcache_status = 'failed'
      })
      .catch(err => {
        this.clearcache_status = 'error'
      })
    },

    isNumber(value) 
    {
      return typeof value === 'number' && isFinite(value);
    },

    //
    addResourceLink(level) {
      if(level == "screen" && this.screen_id != null && this.isNumber(this.screen_id.id) > 0)
      {
        CampaignController.addResourceLink(
            {
              campaignBurstScreenId: this.screen_id.id, 
              ResourceShortName: this.selectedResourceLinkShortName
            })
          .then((res) => {
            this.$root.$emit('snackbarSuccess', 'Screen Resource Link added successfully.')
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', 'Error saving Resource Link, is the CampaignBurstScreenId + ResourceType correct?')
          })
      }
      else if(level == "burst" && this.burst_id != null && this.isNumber(this.burst_id.id) > 0)
      {
        CampaignController.addResourceLink(
            {
              campaignBurstId: this.burst_id.id, 
              ResourceShortName: this.selectedResourceLinkShortName
            })
          .then((res) => {
            this.$root.$emit('snackbarSuccess', 'Burst Resource Link added successfully.')
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', 'Error saving Resource Link, is the CampaignBurstId + ResourceType correct?')
          })
      }
      else if(level == "campaign" && this.campaign_id != null && this.isNumber(this.campaign_id.id) > 0)
      {
        CampaignController.addResourceLink(
            {
              campaignId: this.campaign_id.id, 
              ResourceShortName: this.selectedResourceLinkShortName
            })
          .then((res) => {
            this.$root.$emit('snackbarSuccess', 'Campaign Resource Link added successfully.')
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', 'Error saving Resource Link, is the CampaignId + ResourceType correct?')
          })
      }
      else
      {
        this.$root.$emit('snackbarError', 'Error saving: Invalid ' + level + ' level id')
      }
    },
    formatDate(date) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const formattedDate = new Date(date).toLocaleDateString('en-GB', options);
      return formattedDate.replace(/\//g, '-');
    },
    formatDateRange(dateRange) {
      if (dateRange.length === 2) {
        const [startDate, endDate] = dateRange;
        return `${this.formatDate(startDate)} to ${this.formatDate(endDate)}`;
      }
      return dateRange.length === 1 ? this.formatDate(dateRange[0]) : '';
    },
    addParam() {
      if (this.selectedKey && this.keyValue) {
        this.paramsList.push({ key: this.selectedKey, value: this.keyValue });
        this.keyValue = ''; // Reset the value field
        this.$forceUpdate();
      }
    },
    resetAll() {
      this.selectedDateTimeRange = null;
      this.formattedDateRange = null;
      this.datePickerRange = null;
      this.customFileName = '';
      this.selectedKey = null;
      this.keyValue = '';
      this.paramsList = [];
    },
    async directExportPlayout() {
      let whereParams = {
        fromstartdate: this.selectedDateTimeRange[0],
        tostartdate: this.selectedDateTimeRange[1]
      };

      this.paramsList.forEach(param => {
        let modifiedValue = param.value.replace(/\s+/g, '%').replace(/%+/g, '%');
        whereParams[param.key] = modifiedValue;
      });

      let whereParamsConcat = Object.keys(whereParams)
        .map(key => `${key}=${whereParams[key]}`)
        .join(',');

      this.downloadingReport = true;

      try {
        let res = await ReportingController.getPlayoutReportDirectExport(this.granularity, whereParamsConcat, this.customFileName)
      }
      catch (err) {
        console.error('Error downloading the report:', err);
      } finally {
        this.downloadingReport = false;
      }
    },
  }
}
</script>

<style scoped>
  h2 {
    width: fit-content;
    padding: 10px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
  }
</style>